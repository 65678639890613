import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Callout = makeShortcode("Callout");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Column = makeShortcode("Column");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hackathons`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illustrations/Short-2.png",
          "alt": "Blockchain"
        }}></img></p>
      <Box mdxType="Box">
        <h3>{`Hackathons encourage diverse groups to hack on prototypes for new products and services`}</h3>
      </Box>
    </InfoBlock>
    <Callout secondary icon="question" mdxType="Callout">
      <p>{`If you’re an organizer of a hackathon and want to request funding, you can `}<a parentName="p" {...{
          "href": "/funding/hackathon-funding"
        }}>{`apply for a Dai grant.`}</a></p>
    </Callout>
    <h2>{`First Hackathon?`}</h2>
    <p>{`Hackathons are a great way to meet new and talented people, learn new skills, get access to the latest and most excellent tools, and get noticed by communities like MakerDAO. `}</p>
    <p>{`We've prepared a guide to help you understand what to expect. It covers what you should bring and some best practices for navigating these events and integrating what you learn afterward.`}</p>
    <Aligner center mdxType="Aligner">
      <Box sx={{
        marginBottom: 3
      }} mdxType="Box">
        <Button to="/contribute/hackathons/guide-to-hacking" mdxType="Button">Guide to Hacking</Button>
      </Box>
    </Aligner>
    <h2>{`Fast Track to Development Grants`}</h2>
    <p>{`Our development grants program uses hackathons to find suitable candidates to fast-track. If you're looking for a grant, taking part in a hackathon is a great way to get our attention.`}</p>
    <Aligner center mdxType="Aligner">
      <Box sx={{
        marginBottom: 3
      }} mdxType="Box">
        <Button to="/funding/development-grants/" mdxType="Button">Development Grants</Button>
      </Box>
    </Aligner>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <Column mdxType="Column">
        <Box mdxType="Box">
          <h2>{`Organizing a Hackathon?`}</h2>
          <p>{`If you're organizing a hackathon that involves Maker or DAI as an integral part, we might help fund it. Find out if your hackathon is eligible.`}</p>
          <Box sx={{
            marginBottom: 3
          }} mdxType="Box">
            <Link to="/funding/hackathon-funding" mdxType="Link">Funding for Hackathons</Link>
          </Box>
          <p>{`For other questions, please email us at `}<a parentName="p" {...{
              "href": "mailto:hackathons@makerdao.com"
            }}>{`hackathons@makerdao.com`}</a>{`
or reach out in our community chat.`}</p>
          <Aligner center mdxType="Aligner">
            <Button to="https://chat.makerdao.com/channel/community-development" icon="rocketchat" mdxType="Button">Community Chat</Button>
          </Aligner>
        </Box>
      </Column>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      